import { ActionDialog } from "../_components/ActionDialog";
import React from "react";
import PropTypes from 'prop-types'
import { userActions, alertActions } from '../_actions';
import { connect } from 'react-redux'
import { FaTrash } from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {AddApiKey, DeleteApiKeyDialog} from '.'


class ApiKeysDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            valid: true,
            page: 1,
            sizePerPage: 10,
            showDialog: false,
            key: null
        }
        this.columns = [{
            dataField: 'created',
            text: 'Created',
            headerStyle: { width: "160px" },
            style: { fontWeight: 'normal' },
            sort: true,
            formatter: (cellContent, row) => { 
                return new Date(parseInt(cellContent)).toLocaleString()
            }
        }, {
            dataField: 'name',
            text: 'Name',
            headerStyle: { width: "160px" },
            style: { fontWeight: 'bold' },
            sort: true
        }, {
            dataField: 'name',
            headerStyle: { width: "50px" },
            text: 'Delete',
            style: {
                padding: 0
            },
            formatter: (cellContent) => { 
                return <button className="btn delete-button" data-name={cellContent} onClick={this.deleteDialog.bind(this)}><FaTrash size="20px" /></button> 
            }
        }];
    }
    deleteDialog = (e) => {     
        this.setState({showDialog: true, name: e.currentTarget.dataset.name })
    }
    componentDidMount = async () => {
        this.props.getApiKeys(this.props.username)
    } 
    setValid = (valid) => {
        this.setState({ valid })
    }
    dialogContent = () => {
        const { sizePerPage, page } = this.state;
        return <>
            <AddApiKey username={this.props.username}></AddApiKey>
            <DeleteApiKeyDialog 
                username={this.props.username} 
                key={this.state.key}
                name={this.state.name}
                show = {this.state.showDialog}           
                close = {() => {this.setState({showDialog : false})}}                
                ></DeleteApiKeyDialog>
            <BootstrapTable
            bootstrap4
            keyField="name"
            hover
            bordered={false}
            ref="apikeystable"
            data={this.props.apikeys}
            columns={this.columns}
            filter={filterFactory()}
            pagination={paginationFactory({ page, sizePerPage })}
        /></>
    }
    render() {
        const objectName = "user"
        return <ActionDialog
            objectName={objectName}
            objectValue={this.props.username}
            show={this.props.show}
            hide={this.props.close}
            title={"Manage API Keys of user: " + this.props.username}
            body={this.dialogContent()}
        ></ActionDialog>
    }
}
ApiKeysDialog.propTypes = {
    username: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

const actionCreators = {
    getApiKeys: userActions.getApiKeys,
    message: alertActions.message,
    progress: alertActions.progress,
}
function mapState(state) {
    const { user } = state.authentication;
    const { impersonate } = state.usertree
    const { success, progress, apikeys } = state.users;
    return { user, impersonate, userMessage: { success, progress }, apikeys };
}
const connectedApiKeysDialog = connect(mapState, actionCreators)(ApiKeysDialog)
export { connectedApiKeysDialog as ApiKeysDialog }