import React from 'react';
import { connect } from 'react-redux';
import { userActions, alertActions } from '../_actions';
import { Button, Form, Col } from 'react-bootstrap';
import { AllowedRoles } from '../_components';

class AddWhitelisting extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ip: null
		};
	}
	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	submit = async () => {
		this.props.addWhitelisting(this.props.username, this.state.ip)
	};
	render() {
		return (
			<div className="mb-1">
				<AllowedRoles roles={['admin']}>
					<Form>
						<Form.Row>
							<Col>
								<Form.Control
									name="ip"
									placeholder="IP address"
									value={this.state.ip}
									onChange={this.handleChange}
								/>
							</Col>
							<Col>
								<Button onClick={this.submit}>
									Add whitelisted IP address
								</Button>
							</Col>							
						</Form.Row> 						
					</Form>
				</AllowedRoles>
			</div>
		);
	}
}
const actionCreators = {
	message: alertActions.message,
	progress: alertActions.progress,
	addWhitelisting: userActions.addWhitelisting
};
function mapState(state) {
	const { authentication, } = state;
	const { user } = authentication;
	const { impersonate } = state.usertree;
	return { user, impersonate };
}
const connectedAddWhitelisting = connect(mapState, actionCreators)(AddWhitelisting);
export { connectedAddWhitelisting as AddWhitelisting };
