export const zoneConstants = {
    CREATE_REQUEST: 'ZONES_CREATE_REQUEST',
    CREATE_SUCCESS: 'ZONES_CREATE_SUCCESS',
    CREATE_FAILURE: 'ZONES_CREATE_FAILURE',

    SYNC_REQUEST: 'ZONES_SYNC_REQUEST',
    SYNC_SUCCESS: 'ZONES_SYNC_SUCCESS',
    SYNC_FAILURE: 'ZONES_SYNC_FAILURE',

    UPDATEOWNER_REQUEST: 'ZONES_UPDATEOWNER_REQUEST',
    UPDATEOWNER_SUCCESS: 'ZONES_UPDATEOWNER_SUCCESS',
    UPDATEOWNER_FAILURE: 'ZONES_UPDATEOWNER_FAILURE',

    DELETE_REQUEST: 'ZONES_DELETE_REQUEST',
    DELETE_SUCCESS: 'ZONES_DELETE_SUCCESS',
    DELETE_FAILURE: 'ZONES_DELETE_FAILURE',

    FILTER_REQUEST: 'ZONES_FILTER_REQUEST',
    FILTER_SUCCESS: 'ZONES_FILTER_SUCCESS',
    FILTER_FAILURE: 'ZONES_FILTER_FAILURE',

    REFRESH: 'ZONES_REFRESH'


};