export const recordConstants = {
    CREATE_REQUEST: 'RECORDS_CREATE_REQUEST',
    CREATE_SUCCESS: 'RECORDS_CREATE_SUCCESS',
    CREATE_FAILURE: 'RECORDS_CREATE_FAILURE',

    UPDATE_REQUEST: 'RECORDS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'RECORDS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'RECORDS_UPDATE_FAILURE',

    DELETE_REQUEST: 'RECORDS_DELETE_REQUEST',
    DELETE_SUCCESS: 'RECORDS_DELETE_SUCCESS',
    DELETE_FAILURE: 'RECORDS_DELETE_FAILURE',

    GETALL_REQUEST: 'RECORDS_GETALL_REQUEST',
    GETALL_SUCCESS: 'RECORDS_GETALL_SUCCESS',
    GETALL_FAILURE: 'RECORDS_GETALL_FAILURE',
};