import { ActionDialog } from "../_components/ActionDialog";
import React from "react";
import PropTypes from 'prop-types'
import { userActions, alertActions } from '../_actions';
import { connect } from 'react-redux'

class DeleteApiKeyDialog extends React.Component {
    constructor (props) {
        super(props)
        this.state = {   
        }
    }
    submitDelete = async () => {   
        this.props.close()
        await this.props.deleteApiKey(this.props.username, this.props.name)
        this.setState({ showDialog : false })
    }
    setValid = (valid) =>  {
        this.setState({valid})
    }
    render () {
        const objectName = "API key"
        return  <ActionDialog
            objectName = {objectName}
            objectValue = {this.props.username}
            show       = {this.props.show}
            hide       = {this.props.close}
            action     = {this.submitDelete}
            title      = {"Delete " + objectName+ ": " + this.props.name}
            body       = {"Do you want to delete the "+objectName+" "+this.props.name+"? Applications using this key would not be able to connect to the API anymore and would fail."}
            buttonText = {"Delete"}     
        ></ActionDialog> 
    }


}
DeleteApiKeyDialog.propTypes = {    
    username  : PropTypes.string.isRequired,
    key  : PropTypes.string.isRequired,
    name : PropTypes.string.isRequired,
    show : PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

const actionCreators = {
    deleteApiKey: userActions.deleteApiKey,
    message : alertActions.message,
    progress: alertActions.progress,
  }
  function mapState(state) {
    const { user } = state.authentication;
    const { impersonate } = state.usertree
    const { success, progress } = state.users;
    return { user, impersonate,  userMessage : {success,progress} };
  }
  const connectedDeleteApiKeyDialog = connect(mapState, actionCreators)(DeleteApiKeyDialog)
  export {connectedDeleteApiKeyDialog as DeleteApiKeyDialog}