export const workerConstants = {
    SET_FILTER: 'WORKERS_SET_FILTER',
    
    FILTER_REQUEST: 'WORKERS_FILTER_REQUEST',
    FILTER_SUCCESS: 'WORKERS_FILTER_SUCCESS',
    FILTER_FAILURE: 'WORKERS_FILTER_FAILURE',

    CREATE_REQUEST: 'WORKERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'WORKERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'WORKERS_CREATE_FAILURE',

    UPDATE_REQUEST: 'WORKERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'WORKERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'WORKERS_UPDATE_FAILURE',

    DELETE_REQUEST: 'WORKERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'WORKERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'WORKERS_DELETE_FAILURE',
};
