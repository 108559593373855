import { connect } from 'react-redux';
import { userActions, alertActions } from '../_actions';
import React from 'react';
import { Button } from 'react-bootstrap';
import { WhitelistingDialog } from './WhitelistingDialog';



class WhitelistingButton extends React.Component {
    constructor (props) { 
        super(props)
        this.state = {
            show: false
        }
    }
    show = async () =>  {
        this.setState({show: true})
    }
    close = async () =>  {
        this.setState({show: false})
    }
    render() {
       return  <>
            <Button onClick={this.show} disabled={this.props.users.loading}>IP Whitelisting</Button>
            <WhitelistingDialog username={this.props.username} close={this.close} show={this.state.show}></WhitelistingDialog>
        </>
    }
}

function mapState(state) {
    const {  authentication, users  } = state;
    const { user,qr } = authentication;
    return { user,qr,users  };
}

const actionCreators = {
    message : alertActions.message,
	progress: alertActions.progress,
    resetPassword: userActions.resetPassword 
};

const WhitelistingButtonconnected = connect(mapState, actionCreators)(WhitelistingButton);
export  { WhitelistingButtonconnected as WhitelistingButton }