import { ActionDialog } from "../_components/ActionDialog";
import React from "react";
import PropTypes from 'prop-types'
import { userActions, alertActions } from '../_actions';
import { connect } from 'react-redux'
import { FaTrash } from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {AddWhitelisting, DeleteWhitelistingDialog} from '.'


class WhitelistingDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            valid: true,
            page: 1,
            sizePerPage: 10,
            showDialog: false,
            key: null
        }
        this.columns = [{
            dataField: 'ip',
            text: 'IP',
            headerStyle: { width: "160px" },
            style: { fontWeight: 'bold' },
            sort: true
        }, {
            dataField: 'ip',
            headerStyle: { width: "50px" },
            text: 'Delete',
            style: {
                padding: 0
            },
            formatter: (cellContent) => { 
                return <button className="btn delete-button" data-ip={cellContent} onClick={this.deleteDialog.bind(this)}><FaTrash size="20px" /></button> 
            }
        }];
    }
    deleteDialog = (e) => {     
        this.setState({showDialog: true, ip: e.currentTarget.dataset.ip })
    }
    componentDidMount = async () => {
        this.props.getWhitelisting(this.props.username)
    } 
    setValid = (valid) => {
        this.setState({ valid })
    }
    dialogContent = () => {
        const { sizePerPage, page } = this.state;
        return <>
            <AddWhitelisting username={this.props.username}></AddWhitelisting>
            <DeleteWhitelistingDialog 
                username={this.props.username} 
                ip={this.state.ip}
                show = {this.state.showDialog}           
                close = {() => {this.setState({showDialog : false})}}                
                ></DeleteWhitelistingDialog>
            <BootstrapTable
            bootstrap4
            keyField="ip"
            hover
            bordered={false}
            ref="whitelistingtable"
            data={this.props.whitelisting}
            columns={this.columns}
            filter={filterFactory()}
            pagination={paginationFactory({ page, sizePerPage })}
        /></>
    }
    render() {
        const objectName = "user"
        return <ActionDialog
            objectName={objectName}
            objectValue={this.props.username}
            show={this.props.show}
            hide={this.props.close}
            title={"IP whitelisting for user: " + this.props.username}
            body={this.dialogContent()}
        ></ActionDialog>
    }
}
WhitelistingDialog.propTypes = {
    username: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}

const actionCreators = {
    getWhitelisting: userActions.getWhitelisting,
    message: alertActions.message,
    progress: alertActions.progress,
}
function mapState(state) {
    const { whitelisting } = state.users;
    return { whitelisting };
}
const connectedWhitelistingDialog = connect(mapState, actionCreators)(WhitelistingDialog)
export { connectedWhitelistingDialog as WhitelistingDialog }