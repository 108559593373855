export const usertreeConstants = {
    DESCENDANT_REQUEST: 'USERTREE_DESCENDANT_REQUEST',
    DESCENDANT_SUCCESS: 'USERTREE_DESCENDANT_SUCCESS',
    DESCENDANT_FAILURE: 'USERTREE_DESCENDANT_FAILURE',

    ROOT_DESCENDANT_REQUEST: 'USERTREE_ROOT_DESCENDANT_REQUEST',
    ROOT_DESCENDANT_SUCCESS: 'USERTREE_ROOT_DESCENDANT_SUCCESS',
    ROOT_DESCENDANT_FAILURE: 'USERTREE_ROOT_DESCENDANT_FAILURE',

    CHILDREN_REQUEST: 'USERTREE_CHILDREN_REQUEST',
    CHILDREN_SUCCESS: 'USERTREE_CHILDREN_SUCCESS',
    CHILDREN_FAILURE: 'USERTREE_CHILDREN_FAILURE',

    IMPERSONATE: 'USERTREE_IMPERSONATE',
    EXPANDED_REQUEST: 'USERTREE_EXPANDED_REQUEST',
    EXPANDED_SUCCESS: 'USERTREE_EXPANDED_SUCCESS',
    EXPANDED_FAILURE: 'USERTREE_EXPANDED_FAILURE',
    SET_DATA: 'USERTREE_SET_DATA',

    REFRESH_REQUEST: 'USERTREE_REFRESH_REQUEST',
    REFRESH_SUCCESS: 'USERTREE_REFRESH_SUCCESS',
    REFRESH_FAILURE: 'USERTREE_REFRESH_FAILURE',    
};
