export const userConstants = {
    FILTER_REQUEST: 'USERS_FILTER_REQUEST',
    FILTER_SUCCESS: 'USERS_FILTER_SUCCESS',
    FILTER_FAILURE: 'USERS_FILTER_FAILURE',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'USERS_CREATE_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    REAUTH_REQUEST: 'USERS_REAUTH_REQUEST',
    REAUTH_SUCCESS: 'USERS_REAUTH_SUCCESS',
    REAUTH_FAILURE: 'USERS_REAUTH_FAILURE',

    GET_QR_REQUEST: 'USERS_GET_QR_REQUEST',
    GET_QR_SUCCESS: 'USERS_GET_QR_SUCCESS',
    GET_QR_FAILURE: 'USERS_GET_QR_FAILURE',

    RESET_QR_REQUEST: 'USERS_RESET_QR_REQUEST',
    RESET_QR_SUCCESS: 'USERS_RESET_QR_SUCCESS',
    RESET_QR_FAILURE: 'USERS_RESET_QR_FAILURE',

    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'USERS_UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'USERS_UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'USERS_UPDATE_PASSWORD_FAILURE',

    VERIFY_USER_TOKEN_REQUEST: 'USERS_VERIFY_USER_TOKEN_REQUEST',
    VERIFY_USER_TOKEN_SUCCESS: 'USERS_VERIFY_USER_TOKEN_SUCCESS',
    VERIFY_USER_TOKEN_FAILURE: 'USERS_VERIFY_USER_TOKEN_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',
    
    ACTIVATE_2FA_REQUEST : 'USERS_ACTIVATE_2FA_REQUEST',
    ACTIVATE_2FA_SUCCESS : 'USERS_ACTIVATE_2FA_SUCCESS',
    ACTIVATE_2FA_FAILURE : 'USERS_ACTIVATE_2FA_FAILURE',

    FORCE_2FA_REQUEST : 'USERS_FORCE_2FA_REQUEST',
    FORCE_2FA_SUCCESS : 'USERS_FORCE_2FA_SUCCESS',
    FORCE_2FA_FAILURE : 'USERS_FORCE_2FA_FAILURE', 

    SET_CODE : 'USERS_SET_CODE',
    SET_SOCKET : 'USERS_SET_SOCKET',

    VERIFY_TOPTP_CODE_REQUEST : 'USERS_VERIFY_TOPTP_CODE_REQUEST',
    VERIFY_TOPTP_CODE_SUCCESS : 'USERS_VERIFY_TOPTP_CODE_SUCCESS',
    VERIFY_TOPTP_CODE_FAILURE : 'USERS_VERIFY_TOPTP_CODE_FAILURE',    

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    GET_IP_WHITELISTING_REQUEST: 'USERS_GET_IP_WHITELISTING_REQUEST',
    GET_IP_WHITELISTING_SUCCESS: 'USERS_GET_IP_WHITELISTING_SUCCESS',
    GET_IP_WHITELISTING_FAILURE: 'USERS_GET_IP_WHITELISTING_FAILURE',

    ADD_IP_WHITELISTING_REQUEST: 'USERS_ADD_IP_WHITELISTING_REQUEST',
    ADD_IP_WHITELISTING_SUCCESS: 'USERS_ADD_IP_WHITELISTING_SUCCESS',
    ADD_IP_WHITELISTING_FAILURE: 'USERS_ADD_IP_WHITELISTING_FAILURE',  

    DELETE_IP_WHITELISTING_REQUEST: 'USERS_DELETE_IP_WHITELISTING_REQUEST',
    DELETE_IP_WHITELISTING_SUCCESS: 'USERS_DELETE_IP_WHITELISTING_SUCCESS',
    DELETE_IP_WHITELISTING_FAILURE: 'USERS_DELETE_IP_WHITELISTING_FAILURE',

    GET_API_KEYS_REQUEST: 'USERS_GET_API_KEYS_REQUEST',
    GET_API_KEYS_SUCCESS: 'USERS_GET_API_KEYS_SUCCESS',
    GET_API_KEYS_FAILURE: 'USERS_GET_API_KEYS_FAILURE',      

    ADD_API_KEY_REQUEST: 'USERS_ADD_API_KEY_REQUEST',
    ADD_API_KEY_SUCCESS: 'USERS_ADD_API_KEY_SUCCESS',
    ADD_API_KEY_FAILURE: 'USERS_ADD_API_KEY_FAILURE',  

    DELETE_API_KEY_REQUEST: 'USERS_DELETE_API_KEY_REQUEST',
    DELETE_API_KEY_SUCCESS: 'USERS_DELETE_API_KEY_SUCCESS',
    DELETE_API_KEY_FAILURE: 'USERS_DELETE_API_KEY_FAILURE'   
};
